.footer {
    background-color: #99B7AB; /* Change this to your desired color */
  }

h5.footer {
  color: #ffff;
  font-weight: 600;
  margin-top: 25px;
}

  .footer .col-md-6 {
    padding: 10px;
  }
  
  .footer ul {
    list-style: none; /* Remove list markers */
    padding: 0;
  }
  
  .footer ul li {
    margin-bottom: 10px;
  }
  
  .footer ul li a {
    color: #ffff; /* Customize link color */
    text-decoration: none; /* Remove underline */
  }
  
  .footer ul li a:hover {
    text-decoration: none; /* Add underline on hover */
  }