/* ProductCard.css */

.product-card {
    border-radius: 15px; /* Rounded corners */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    overflow: hidden; /* Ensure content doesn't overflow */
    border-style: hidden;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: inherit;
    max-width: 300px; /* Set a maximum width for the product cards */
    margin: 0 auto; /* Center align the cards within their columns */
    position: relative;
}

.product-card:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Slightly larger shadow on hover */
    text-decoration: none;
}

.product-card img {
    max-height: 325px;
    object-fit: cover; /* Maintain aspect ratio */
}

.product-card .card-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
    min-height: 110px;
}

.product-card .card-title {
    font-size: 1.1rem;
    font-weight: normal;
    color: #333; /* Ensure consistent text color */
}

.product-card .card-title:hover {
    text-decoration: none;
}

.product-card .card-price {
    font-size: 1.2em;
    color: #257281;
    margin-bottom: unset;
}

.product-card a {
    color: inherit; /* Ensure link text color does not change */
    text-decoration: none; /* Remove underline from links */
}

.product-card a:hover {
    color: inherit; /* Ensure link text color does not change on hover */
    text-decoration: none; /* Ensure no underline on hover */
}

.card-price-supplier {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.heart-icon {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 24px;
    color: #257281;
    cursor: pointer;
}

.supplier-logo {
    margin-bottom: 10px;
    display: contents;
}

.supplier-logo img {
    max-height: 30px; /* Adjust the size as needed */
    max-width: 100%;
}

.checkbox-container {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
}