/* ProductList.css */

/* Grid layout for product cards */
.product-list .row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .product-list .col-6,
  .product-list .col-sm-6,
  .product-list .col-md-4 {
    padding: 5px;
  }
  
  /* Loading more products text */
  .product-list .text-center {
    margin-top: 20px;
    font-size: 1.1em;
  }