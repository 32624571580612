
.dashboard-container {
    display: flex;
    min-height: 100vh;
    position: relative; /* Ensure positioning relative to handle the absolute sidebar */
  }
  .toggle-btn {
    background: none;
    border: none;
    font-size: 1.5em;
    margin: 1em;
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 20px;
  }
  
  .user-info {
    display: flex;
    align-items: center;
    padding: 1em;
  }
  
  .user-img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  
  .user-details {
    margin-left: 1em;
  }
  
  nav ul {
    list-style: none;
    padding: 0;
  }
  .content {
    flex: 1;
    padding: 20px;
    margin-left: 75px;
  }
  
  .profile-form {
    max-width: 400px;
    margin: 0 auto;
    border-radius: 15px;
    border-style: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .slider {
    width: 100%;
    height: 25px;
  }
  
  .track {
    background: #ddd;
    height: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .thumb {
    height: 20px;
    width: 20px;
    background-color: #FF9775;
    border-radius: 50%;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .price-label {
    text-align: center;
    margin-top: 10px;
  }

  @media (min-width: 1000px) {
    .content {
      margin-left: 300px;
  
    }
  }
