/* Filters.css */

/* General styles for all filters */
.filter-section {
  margin-bottom: 20px;
}

.filter-section h5 {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.2em;
}

.form-check {
  margin-bottom: 10px;
}

.form-check-input {
  margin-right: 10px;
  cursor: pointer;
}

.form-check-label {
  cursor: pointer;
}

/* PriceFilter specific styles */
.price-filter .price-label {
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

/* SupplierFilter specific styles */
.supplier-filter .form-check {
  display: flex;
  align-items: center;
}

.supplier-filter .form-check-label {
  margin-left: 5px;
}

/* CategoryFilter specific styles */
.category-filter .form-select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

/* LikedProductsFilter specific styles */
.liked-products-filter .form-check {
  display: flex;
  align-items: center;
}

.liked-products-filter .form-check-label {
  margin-left: 5px;
}