/* ZoolmaatjesView.css */
.viewWrapper{ 
    margin-left: 4%;
}
.col-6 {
  padding: 5px 5px 0px;
  max-width: 300px;
  }
.product-card {
    border-radius: 15px; /* Rounded corners */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    overflow: hidden; /* Ensure content doesn't overflow */
    border-style: hidden;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: inherit;
    max-width: 300px; /* Set a maximum width for the product cards */
    margin: 0 auto; /* Center align the cards within their columns */
  }
  
  .product-card:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Slightly larger shadow on hover */
    text-decoration: none;
  }
  
  .product-card img {
    max-height: 325px;
    object-fit: cover; /* Maintain aspect ratio */
  }
  
  .product-card-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5rem;
  }
  
  .product-card .card-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
  }
  
  .product-card .card-title {
    font-size: 1.1rem;
    font-weight: normal;
    color: #333; /* Ensure consistent text color */
  }
  
  .product-card .card-price {
    font-size: 1.2em;
    color: #257281;
    margin-bottom: unset;
  }
  
  .product-card .card-title:hover {
    text-decoration: none;
  }
  
  .product-card a {
    color: inherit; /* Ensure link text color does not change */
    text-decoration: none; /* Remove underline from links */
  }
  
  .card-price-supplier {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .product-card a:hover {
    color: inherit; /* Ensure link text color does not change on hover */
    text-decoration: none; /* Ensure no underline on hover */
  }
  
.mail-icon {
  border-style:none;
  background-color: #fff;
}
  
  .supplier-logo {
    margin-bottom: 10px;
    display: contents;
  }
  
  .supplier-logo img {
    max-height: 30px; /* Adjust the size as needed */
    max-width: 100%;
  }
  
  .user-profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .user-profile-pics-wrapper {
    display: flex;
    padding: 10px 15px;
  }
  .card-header-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
  }
  
  .user-profile-pics {
    display: flex;
    position: relative;
  }
  
  .user-profile-pics .user-profile-pic.overlap {
    margin-right: -10px; /* Adjust as needed for overlap effect */
    border: 2px solid white; /* Optional: add a white border for better visibility */
    z-index: 3; /* Ensure the left picture overlaps the right one */
  }
  
  .user-profile-pics .user-profile-pic.overlap:nth-child(2) {
    z-index: 2;
  }
  
  .user-profile-pics .user-profile-pic.overlap:nth-child(3) {
    z-index: 1;
  }
  .zoolmaatjes-text {
    margin-left: 2%;
    width: max-content;
  }
  .sizeText {
    font-size: 0.8em;
  }
.modal-header {
  border-style: none;
}
.modal-content {
  border-radius: 15px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    border-style: hidden;
}
.mailed-icon {
  border-style: unset;
  background-color: transparent;
  cursor:not-allowed;
}
.btn-back {
  background-color: transparent;
  border-style: none;;
  padding-left: 0;
  width: auto;
}
.detail-view-btn {
  border-style: none;
  background-color: transparent;
}
@media (min-width: 615px) {
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}

@media (max-width: 614px) and (min-width: 350px) {
  .match-overview .row .col-sm-6 {
    flex: 0 0 auto;
    width: 100%;
  }
  .detail-view-container .row .col-sm-6 {
    flex: 0 0 auto;
    width: 100%;
  }
}  