:root {
    --bs-primary: #99B7AB; /* Replace with your custom primary color */
    --bs-secondary: #FF9775; /* Replace with your custom secondary color */
    --bs-success: #28a745; /* Customize other colors as needed */
    --bs-danger: #dc3545;
    --bs-warning: #ffc107;
    --bs-info: #17a2b8;
    --bs-light: #f8f9fa;
    --bs-dark: #343a40;
  }
.navbar {
  z-index: 20;
}
.navbar .dropdown-toggle::after {
    display: none;
}
.navbar .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    left: auto;
}
.custom-primary {
    color: #257281 !important;
  }
  .custom-font {
    font-family: 'Nunito';
  }
  .font-weight-bold {
    font-weight: 700;
  }
@media (max-width: 768px) {
  .sticky-header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: white; /* Adjust as needed */
    width: 100%;
  }
}