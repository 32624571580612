h1.blog-title {
    font-weight: 700;
    margin-bottom: 50px;
}

.blog-h2 {
    font-size: 1.5em;
    font-weight: 600;
    margin-top: 50px;
}

.blog-h3 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 50px;
}

.content-image {
  display: block; /* Make the image a block-level element */
  margin: 50px auto; /* Center the image horizontally with top and bottom margins */
  max-width: 100%; /* Ensure the image is responsive */
  max-height: 300px; /* Maintain the aspect ratio */
}

.blog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

@media (min-width: 1200px) {

.blog-container {
    max-width: 960px;
}

}