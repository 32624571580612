body {
  margin: 0;
  padding: 0;
}

.filter-section .filter-title {
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 30px;
}

.filter-section h5 {
  margin-bottom: 20px; /* Adjust the value to increase/decrease space */
}

.col-6 {
  padding: 5px 5px 0px;
}

@media (max-width: 614px) and (min-width: 350px) {
  .col-sm-6 {
      flex: 0 0 auto;
      width: 50%;
  }
}

.btn-primary {
  background-color: #FF9775; /* Bootstrap primary color */
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 25px;
  transition: background-color 0.3s ease;
  width: 100%; /* Make the button full width */
  max-width: 100%; /* Ensure it doesn't exceed the viewport width */
}

.btn-primary:hover {
  background-color: #db7655; /* Darker color on hover */
}

.slider {
  width: 100%;
  height: 25px;
}

.track {
  background: #ddd;
  height: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.thumb {
  height: 20px;
  width: 20px;
  background-color: #FF9775;
  border-radius: 50%;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}

.price-label {
  text-align: center;
  margin-top: 10px;
}

.fixed-alert {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 600px;
  z-index: 1050; /* Ensure it appears above other elements */
}

.d-md-none {
  display: none;
}

@media (max-width: 767.98px) {
  .d-md-none {
    display: block;
    margin: 20px auto; /* Center the button */
  }
}

.fixed-filter-button {
  position: fixed;
  top: 75px;
  right: 0px;
  z-index: 1000;
  transition: top 0.3s ease;
}

.fixed-filter-button.sticky {
  position: fixed;
  top: 0;
  right: 20px;
}