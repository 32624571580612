.register-bg {
    height: 100vh; /* Full viewport height */
    width: 100%; /* Full viewport width */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fa; /* Light grey background */
  }
  .btn-custom {
    background-color: #ff8364; /* Replace with your desired color */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
  }
  
  .btn-custom:hover {
    background-color: #e57252; /* Darker shade on hover */
  }
  
  .btn-google {
    border: 1px solid #000; /* Black border */
    color: #000; /* Black text */
    background-color: #fff; /* White background */
    padding: 10px 20px;
    border-radius: 50px;
  }
  
  .btn-google:hover {
    background-color: #f0f0f0; /* Slightly darker background on hover */
  }
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-control-sm {
    max-width: 300px; /* Adjust the max-width as needed */
    margin: 0 auto; /* Center the input fields */
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
    max-width: 300px;
  }
  /* Custom styles for range input thumb */
input[type="range"] {
    -webkit-appearance: none; /* Override default CSS styles */
    appearance: none;
    width: 100%;
    height: 5px;
    background: #ddd;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;

  }
  
  input[type="range"]:hover {
    opacity: 1;
  }
  
  /* WebKit browsers */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #ff7f50; /* Change this color to your desired color */
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    position: relative;
    top: -3px; /* Adjust this value to align the thumb on the slider line */
  }
  
  /* Mozilla Firefox */
  input[type="range"]::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #ff7f50; /* Change this color to your desired color */
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    position: relative;
    top: -3px; /* Adjust this value to align the thumb on the slider line */
  }
  
  /* Internet Explorer */
  input[type="range"]::-ms-thumb {
    width: 20px;
    height: 20px;
    background: #ff7f50; /* Change this color to your desired color */
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    position: relative;
    top: -3px; /* Adjust this value to align the thumb on the slider line */
  }