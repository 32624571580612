/* Wrapper for product-links with white background */
.product-links-wrapper {
  padding: 40px 20px; /* Add padding to the wrapper */
  max-width: 1200px; /* Set maximum width for the section */
  margin: 0 auto; /* Center the section */
}

/* General container styling */
.product-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the cards within the grid */
}

/* Card styling */
.product-links .card {
  flex: 1 0 300px; /* Allow cards to grow and shrink with a base size of 300px */
  max-width: 300px; /* Set maximum width for cards */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  margin: 5px;
  border-radius: 0px;
}

.product-links .card:hover {
  transform: scale(1.01);
}

/* Image styling with overlay */
.product-links .card-body {
  height: 0;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-links .card-body::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay */
}


.product-links .card-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
}

/* Content section with background color */
.content-section-wrapper {
  background-color: #FFFBF5;
  padding: 10px;
}

.content-section {
  padding: 20px;
}
/* Skeleton loading styles */
.skeleton {
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 10px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
      background-color: #e0e0e0;
  }
  50% {
      background-color: #f0f0f0;
  }
  100% {
      background-color: #e0e0e0;
  }
}

.skeleton-title {
  height: 500px;
  width: 100%;
}

.skeleton-text {
  height: 30px;
  width: 100%;
}

.skeleton-card {
  height: 300px;
  width: 300px;
}

/* Media query for devices with a max width of 768px (e.g., tablets and small desktops) */
@media (max-width: 768px) {
  }
  
  /* Media query for devices with a max width of 576px (e.g., mobile devices) */
  @media (max-width: 576px) {
}
.custom-button {
    background-color: #FF9775; /* Replace with your desired color */
    color: white; /* Set text color */
    border: none; /* Remove border */
    font-size: 1.2em;
  }
  
  .custom-button:hover {
    background-color: #e97e5a; /* Replace with your desired hover color */
    color: white; /* Ensure text color remains white on hover */
  }
  