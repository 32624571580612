.sidebar {
    width: 75px;
    background-color: #f8f9fa;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    transition: width 0.2s ease;
    position: absolute;
    z-index: 10;
    height: 100%;
  }
  
  .sidebar.open {
    width: 250px;
  }
  
  .toggle-btn {
    background: none;
    border: none;
    font-size: 1.5em;
    margin: 1em;
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 20px;
  }
  
  .user-info {
    display: flex;
    align-items: center;
    padding: 1em;
    flex-direction: column;
    margin-bottom: 25px;
  }
  
  .user-img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  
  .user-details {
    margin-left: 0px;
  }
  
  nav ul {
    display: flex;
    list-style: none;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 50px;
}
  nav ul li {
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: center;
  }
  
  nav ul li .nav-link {
    text-decoration: none;
    color: #333;
    display: flex;
    align-items: center;
  }
  
  nav ul li .nav-link span {
    margin-left: 1em;
  }
  
  .bottom-section ul {
    list-style: none;
    padding: 0;
    margin-bottom: 1em;
    display: flex;
  }
  
  .bottom-section ul li {
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: center;
  }
  .bottom-section ul li .nav-link {
    text-decoration: none;
    color: #333;
    display: flex;
    align-items: center;
  }
  
  .bottom-section ul li .nav-link span {
    margin-left: 1em;
  }